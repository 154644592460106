<template>
  <div class="method-stripe">
    <b-input-group prepend="$" class="mb-1">
      <b-form-input
        :placeholder="`Enter Amount ${between.min}$ - ${between.max}$`"
        type="number"
        v-model="amount"
      />
    </b-input-group>
    <!-- <p class="text-left">
      Note: you will pay {{ fee }}% fees<br />
      <span v-if="amount > 0">
        Total: <strong>{{ totalAmount }} $</strong>
      </span>
    </p> -->

    Note: you will pay {{ fee }}% processing fee<br />
    <br />

    Your payment will be processed through Revolut.<br /><br />
    <strong>
      If the payment window failed to open, we've sent a payment link to your
      email. Please check your inbox to complete your transaction. </strong
    ><br /><br />
    <strong>
      Once complete, manually return to IMEICheck.com to continue your IMEI
      check. </strong
    ><br /><br />

    <span v-if="amount > 0">
      <h3>
        Total: <strong>{{ totalAmount }} $</strong>
      </h3>
    </span>

    <div class="text-error" v-if="amount > between.max">
      Can't pay more than {{ between.max }}$
    </div>
    <br />

    <b-button
      v-show="amount >= between.min && amount <= between.max"
      variant="relief-success"
      class="btn btn-primary btn-lg btn-block"
      @click="submit"
      :disabled="loading"
    >
      <b-spinner v-if="loading" small />
      Pay
    </b-button>
    <!--    <loader :loading="loading"></loader>-->
  </div>
</template>

<script>
import { BButton, BFormInput, BInputGroup } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapMutations } from "vuex";
import Deposit from "@/services/deposit.service";
import loader from "../../../components/FullScreenLoader.vue";

export default {
  components: {
    loader,
    BButton,
    BInputGroup,
    BFormInput,
    ToastificationContent,
  },
  props: ["between"],
  data: () => ({
    amount: "",
    fee: 2,
    loading: false,
    verifyInterval: null,
  }),
  computed: {
    totalAmount() {
      return Number(+this.amount + this.amount * (this.fee / 100)).toFixed(2);
    },
  },
  methods: {
    async submit() {
      try {
        this.loading = true;
        const { order } = await Deposit.revolutCreateOrder({
          price: this.totalAmount,
        });
        const checkoutUrl = order.checkout_url;
        const openedTab = window.open(checkoutUrl, "_blank");

        /*   const timeInterval = 5
          const timeOut = 60
          let currentTime = 0
          let isApiCallInProgress = false
          this.verifyInterval = setInterval(async () => {
            const verifiedData = await Deposit.verifyRevolutOrder(order.id)
            if (currentTime > timeOut && verifiedData.order.state != 'COMPLETED') {
              console.log('not completed..')
              this.clearVerifyInterval()
            } else if (verifiedData.order.state == 'COMPLETED') {
              this.clearVerifyInterval()
              this.complete(this.amount)
            } else if (openedTab.closed && verifiedData.order.state != 'COMPLETED') {
              this.clearVerifyInterval()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Deposit Failure`,
                  icon: 'CheckIcon',
                  text: `Deposit is not completed.`,
                  variant: 'error',
                },
              })
            }
            currentTime = currentTime + timeInterval
          }, timeInterval * 1000) */
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    clearVerifyInterval() {
      clearInterval(this.verifyInterval);
      this.loading = false;
    },
    ...mapMutations(["HANDLE_CREDIT"]),
    complete(amount) {
      this.HANDLE_CREDIT(+amount);
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Deposit Made`,
          icon: "CheckIcon",
          text: `You have deposit ${amount}$`,
          variant: "success",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  border: 1px dashed;
  padding: 20px;
  background: #161e312b;
  text-align: left;
}
</style>
